import React, { useState }  from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import PvtCore from '@crocarneiro/pvtcore';
import InputFields from '../../components/InputFields';
import OutputFields from '../../components/OutputFields';
import {
    convertDensityToGcm3,
    convertPressureToPsi,
    convertTemperatureToCelsius
} from '../../utils/UnityConversor';
import useFeature from '../../hooks/useFeature';

interface Result {
    density: number,
    compressibility: number,
    thermExp: number
}

export default function Calculator() {
    const densitySignificantFigures = 3;

    //#region state
    /* Input values. */
    let [density, setDensity] = useState<number | string>('');
    let [temperature, setTemperature] = useState<number | string>('');
    let [pressure, setPressure] = useState<number | string>('');

    /* Input errors */
    let [thereIsInputError, setThereIsInputError] = useState<boolean>(false);

    /* Output values. */
    let [resultDensity, setResultDensity] = useState<string>('');
    let [resultCompressibility, setResultCompressibility] = useState<string>('');
    let [resultThermalExpansion, setResultThermalExpansion] = useState<string>('');

    /* Units for input. */
    let [unitDensity, setUnitDensity] = useState('GCM3');
    let [unitTemperature, setUnitTemperature] = useState('C');
    let [unitPressure, setUnitPressure] = useState('PSI');

    /* Units for output. */
    let [unitDensityOut, setUnitDensityOut] = useState('GCM3');
    let [unitCompressibility, setUnitCompressibility] = useState('1/PSI');
    let [unitThermalExpansion, setUnitThermalExpansion] = useState('1/K');
    //#endregion

    //#region features
    let horizontalFlow = useFeature('horizontal_flow');
    let topTextFeature = useFeature('top_text');
    let bottomTextFeature = useFeature('bottom_text');
    //#endregion

    const handleOnInputError = (thereIsError:boolean) => {
        setThereIsInputError(thereIsError);
    }

    const calculate = ():Result => {
        let newDensity:number = Number(density.valueOf());
        let newTemperature:number = Number(temperature.valueOf());
        let newPressure:number = Number(pressure.valueOf());

        newDensity = convertDensityToGcm3(newDensity, unitDensity);
        newTemperature = convertTemperatureToCelsius(newTemperature, unitTemperature);
        newPressure = convertPressureToPsi(newPressure, unitPressure);

        return {
            density: PvtCore.Pvt.calcDensity(newDensity, newTemperature, newPressure),
            compressibility: PvtCore.Pvt.calcCompressibility(newDensity, newTemperature, newPressure),
            thermExp: PvtCore.Pvt.calcThermalExpansion(newDensity, newTemperature, newPressure)
        };
    }

    const handleCalcResult = (result:Result) => {
        switch(unitDensityOut) {
            case "GCM3":
                setResultDensity(result.density.toPrecision(densitySignificantFigures));
                break;
            case "SG":
                setResultDensity(PvtCore.Conversions.density.gcm3ToSg(result.density).toPrecision(densitySignificantFigures));
                break;
            case "LBGAL":
                setResultDensity(PvtCore.Conversions.density.gcm3ToLbgal(result.density).toPrecision(densitySignificantFigures));
                break;
            case "PPTF":
                setResultDensity(PvtCore.Conversions.density.gcm3ToPptf(result.density).toPrecision(densitySignificantFigures));
                break;
        }

        switch(unitCompressibility) {
            case "1/PSI":
                setResultCompressibility(result.compressibility.toExponential(2));
                break;
            case "1/BAR":
                setResultCompressibility(PvtCore.Conversions.compressibility.onePsiToOneBar(result.compressibility).toExponential(2));
                break;
            case "1/PA":
                setResultCompressibility(PvtCore.Conversions.compressibility.onePsiToOnePa(result.compressibility).toExponential(2));
                break;
        }

        switch(unitThermalExpansion) {
            case "1/K":
                setResultThermalExpansion(result.thermExp.toExponential(2));
                break;
            case "1/C":
                setResultThermalExpansion(PvtCore.Conversions.thermalExpansion.oneKToOneC(result.thermExp).toExponential(2));
                break;
            case "1/F":
                setResultThermalExpansion(PvtCore.Conversions.thermalExpansion.oneKToOneF(result.thermExp).toExponential(2));
                break;
        }
    }

    const handleOnClickButton = () => {
        const result:Result = calculate();

        handleCalcResult(result);
    }

    const handleOnDensityUnitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUnitDensity(event.target.value as string);
    }

    const handleOnTemperatureUnitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUnitTemperature(event.target.value as string);
    }

    const handleOnPressureUnitChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setUnitPressure(event.target.value as string);
    }

    const handleOnUnitDensityOutChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if(resultDensity.length === 0) {
            alert("Please, make a calculation first.");
            return 0;
        }

        const unit = event.target.value as string;
        const result:Result = calculate();

        setUnitDensityOut(unit);

        switch(unit) {
            case "GCM3":
                setResultDensity(result.density.toPrecision(densitySignificantFigures));
                break;
            case "SG":
                setResultDensity(PvtCore.Conversions.density.gcm3ToSg(result.density).toPrecision(densitySignificantFigures));
                break;
            case "LBGAL":
                setResultDensity(PvtCore.Conversions.density.gcm3ToLbgal(result.density).toPrecision(densitySignificantFigures));
                break;
            case "PPTF":
                setResultDensity(PvtCore.Conversions.density.gcm3ToPptf(result.density).toPrecision(densitySignificantFigures));
                break;
        }
    }

    const handleOnUnitCompressibilityChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if(resultCompressibility.length === 0){
            alert("Please, make a calculation first.");
            return 0;
        }

        const unit = event.target.value as string;
        const result:Result = calculate();

        setUnitCompressibility(unit);

        switch(unit) {
            case "1/PSI":
                setResultCompressibility(result.compressibility.toExponential(2));
                break;
            case "1/BAR":
                setResultCompressibility(PvtCore.Conversions.compressibility.onePsiToOneBar(result.compressibility).toExponential(2));
                break;
            case "1/PA":
                setResultCompressibility(PvtCore.Conversions.compressibility.onePsiToOnePa(result.compressibility).toExponential(2));
                break;
        }
    }

    const handleOnUnitThermalExpansionChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        if(resultThermalExpansion.length === 0){
            alert("Please, make a calculation first.");
            return 0;
        }

        const unit = event.target.value as string;
        const result:Result = calculate();

        setUnitThermalExpansion(unit);

        switch(unit) {
            case "1/K":
                setResultThermalExpansion(result.thermExp.toExponential(2));
                break;
            case "1/C":
                setResultThermalExpansion(PvtCore.Conversions.thermalExpansion.oneKToOneC(result.thermExp).toExponential(2));
                break;
            case "1/F":
                setResultThermalExpansion(PvtCore.Conversions.thermalExpansion.oneKToOneF(result.thermExp).toExponential(2));
                break;
        }
    }

    return (
        <Grid
            container
            direction="column"
            alignItems="center"
        >
            <div style={{alignSelf:"flex-start", marginBottom:"25px", paddingRight: "15px", marginLeft: "15px"}}>
                <Typography>
                    {
                    (topTextFeature.on && topTextFeature.value)
                    ?
                    topTextFeature.value
                    :
                    `PVTCalc™ enables calculation of downhole density at temperature and pressure as well as compressibility and thermal expansion of formate brines. This calculator is unique for formate fluids and is based on actual laboratory PVT measurements.`
                    }
                </Typography>
            </div>

            <Grid
                container
                alignItems='center'
                direction={horizontalFlow.on ? 'row' : 'column'}
                justifyContent={horizontalFlow.on ? 'space-around' : 'center'}
            >
                <InputFields
                    density={density}
                    onDensityChange={(e:any) => setDensity(Number(e.target.value))}
                    onInputErrorChange={handleOnInputError}
                    densityUnit={unitDensity}
                    onDensityUnitChange={handleOnDensityUnitChange}
                    temperature={temperature}
                    onTemperatureChange={(e:any) => setTemperature(Number(e.target.value))}
                    temperatureUnit={unitTemperature}
                    onTemperatureUnitChange={handleOnTemperatureUnitChange}
                    pressure={pressure}
                    onPressureChange={(e:any) => setPressure(Number(e.target.value))}
                    pressureUnit={unitPressure}
                    onPressureUnitChange={handleOnPressureUnitChange}
                    style={{width:"250px", marginBottom:"15px"}}
                />

                <Grid>
                    <Button
                        style={{marginTop: "15px", backgroundColor: '#164194', color: 'white'}}
                        variant="contained"
                        onClick={handleOnClickButton}
                        disabled={thereIsInputError}
                    >
                        Calculate
                    </Button>
                </Grid>

                <OutputFields
                    density = {resultDensity}
                    densityUnit = {unitDensityOut}
                    onDensityUnitChange = {handleOnUnitDensityOutChange}

                    compressibility = {resultCompressibility}
                    compressibilityUnit = {unitCompressibility}
                    oncompressibilityUnitChange = {handleOnUnitCompressibilityChange}

                    thermalExpansion = {resultThermalExpansion}
                    thermalExpansionUnit = {unitThermalExpansion}
                    onthermalExpansionUnitChange = {handleOnUnitThermalExpansionChange}

                    style={{width:"250px", marginBottom:"15px"}}
                />
            </Grid>

            <div style={{
                    alignSelf:"flex-start",
                    marginTop:"25px",
                    paddingRight: "15px",
                    marginLeft: "15px",
                }}
            >
                <Typography>
                    {
                    (bottomTextFeature.on && bottomTextFeature.value)
                    ?
                    bottomTextFeature.value
                    :
                    `Valid for potassium formate brines in the density range 1.00–1.57 g/cm³ (8.34–13.1lb/gal), blends of cesium and potassium formate brines in the range 1.57–2.20 g/cm³ (13.1–18.4 lb/gal), and cesium formate brine in the range 2.20–2.30 (18.4–19.2 lb/gal). Properties are calculated for temperatures up to 250°C (482°F) and pressures up to 2,068 bar (30,000 psi).`
                    }
                </Typography>
            </div>
        </Grid>
    )
}