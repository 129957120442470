import PvtCore from "@crocarneiro/pvtcore";

export function convertDensityToGcm3(density:number, unit:string):number {
    switch(unit) {
        case 'GCM3':
            return density;
        case 'SG':
            return PvtCore.Conversions.density.sgToGcm3(density);
        case 'LBGAL':
            return PvtCore.Conversions.density.lbgalToGcm3(density);
        case 'PPTF':
            return PvtCore.Conversions.density.pptfToGcm3(density);
    }

    throw "Invalid density unit";
}

export function convertTemperatureToCelsius(temperature:number, unit:string):number {
    switch(unit) {
        case 'C':
            return temperature;
        case "F":
            return PvtCore.Conversions.temperature.fahrenheitToCelsius(temperature);
    }

    throw "Invalid temperature unit";
}

export function convertPressureToPsi(pressure:number, unit:string):number {
    switch(unit) {
        case 'PSI':
            return pressure;
        case "BAR":
            return PvtCore.Conversions.pressure.barToPsi(pressure);
        case "MPA":
            return PvtCore.Conversions.pressure.mpaToPsi(pressure);
        case "KPA":
            return PvtCore.Conversions.pressure.kpaToPsi(pressure);
    }

    throw "Invalid pressure unit";
}