import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import PvtCore from '@crocarneiro/pvtcore';
import { ErrorType, ValidationResult } from '@crocarneiro/pvtcore';
import { convertDensityToGcm3, convertPressureToPsi, convertTemperatureToCelsius } from '../../utils/UnityConversor';

const useStyles = makeStyles(() => ({
    warningTextField: {
        "& .Mui-error": {
            color: 'orange'
        },
        "& .MuiInput-input": {
            color: 'orange'
        }
    },
    errorTextField: {
        "& .Mui-error": {
            color: 'red'
        },
        "& .MuiInput-input": {
            color: 'red'
        }
    }
}));
interface InputFieldsProps {
    density: number | string,
    onDensityChange: any,
    densityUnit: string,
    onDensityUnitChange: any,

    temperature: number | string,
    onTemperatureChange: any,
    temperatureUnit: string,
    onTemperatureUnitChange: any,

    pressure: number | string,
    onPressureChange: any,
    pressureUnit: string,
    onPressureUnitChange: any,

    onInputErrorChange?: (thereIsInputError:boolean) => void,
    style?:object
}

export default function InputFields(props:InputFieldsProps) {
    const classes = useStyles();
    const [densityError, setDensityError] = useState<string>('');
    const [densityClassName, setDensityClassName] = useState<string>('');

    const [temperatureError, setTemperatureError] = useState<string>('');
    const [temperatureClassName, setTemperatureClassName] = useState('');

    const [pressureError, setPressureError] = useState<string>('');
    const [pressureClassName, setPressureClassName] = useState('');

    const errorMessage:string = 'ERROR. Data input is out of range.';
    const warningMessage:string = 'WARNING. Data output is extrapolated.';

    useEffect(() => {
        if(
            (props.density.toString().length > 0 && props.density !== 0) &&
            (props.pressure.toString().length > 0 && props.pressure !== 0) &&
            (props.temperature.toString().length > 0 && props.temperature !== 0)
        ) {
            if(props.onInputErrorChange !== undefined)
                props.onInputErrorChange(
                    (densityError.length > 0 && densityClassName === classes.errorTextField) ||
                    (temperatureError.length > 0 && temperatureClassName === classes.errorTextField) ||
                    (pressureError.length > 0 && pressureClassName === classes.errorTextField)
            );
        } else {
            if(props.onInputErrorChange !== undefined) props.onInputErrorChange(true);
        }
    }, [props.density,
        props.temperature,
        props.pressure,
        densityError,
        temperatureError,
        pressureError,
        props.onInputErrorChange]);

    const validateDensity = (density:number, densityUnit:string) => {
        const normalizedDensity = convertDensityToGcm3(density, densityUnit);

        const result:ValidationResult = PvtCore.Validations.validateDensity(normalizedDensity);
        setDensityError('');
        if(result.okay) {
            setDensityClassName('');
        } else if(result.errorType === ErrorType.ERROR && result.message) {
            setDensityClassName(classes.errorTextField);
            setDensityError(errorMessage);
        } else if(result.errorType === ErrorType.WARNING && result.message) {
            setDensityClassName(classes.warningTextField);
            setDensityError(warningMessage);
        }
    }

    const handleOnDensityChange = (e:any) => {
        props.onDensityChange(e);

        let density = e.target.value;
        validateDensity(density, props.densityUnit);
    }

    const handleOnDensityUnitChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        props.onDensityUnitChange(event);

        validateDensity(props.density as number, event.target.value as string);
    }

    const validateTemperature = (temperature: number, temperatureUnit: string) => {
        const normalizedTemperature = convertTemperatureToCelsius(temperature, temperatureUnit);

        const result:ValidationResult = PvtCore.Validations.validateTemperature(normalizedTemperature);

        setTemperatureError('');
        if(result.okay) {
            setTemperatureClassName('');
        } else if(result.errorType === ErrorType.ERROR && result.message) {
            setTemperatureClassName(classes.errorTextField);
            setTemperatureError(errorMessage);
        } else if(result.errorType === ErrorType.WARNING && result.message) {
            setTemperatureClassName(classes.warningTextField);
            setTemperatureError(warningMessage);
        }
    }

    const handleOnTemperatureChange = (e:any) => {
        props.onTemperatureChange(e);

        let temperature = e.target.value;
        validateTemperature(temperature as number, props.temperatureUnit);
    }

    const handleOnTemperatureUnitChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        props.onTemperatureUnitChange(event);

        validateTemperature(props.temperature as number, event.target.value as string);
    }

    const validatePressure = (pressure: number, pressureUnit: string) => {
        const normalizedPressure = convertPressureToPsi(pressure, pressureUnit);

        const result:ValidationResult = PvtCore.Validations.validatePressure(normalizedPressure);

        setPressureError('');
        if(result.okay) {
            setPressureClassName('');
        } else if(result.errorType === ErrorType.ERROR && result.message) {
            setPressureClassName(classes.errorTextField);
            setPressureError(errorMessage);
        } else if(result.errorType === ErrorType.WARNING && result.message) {
            setPressureClassName(classes.warningTextField);
            setPressureError(warningMessage);
        }
    }

    const handleOnPressureChange = (e:any) => {
        props.onPressureChange(e);

        let pressure = e.target.value;
        validatePressure(pressure as number, props.pressureUnit);
    }

    const handleOnPressureUnitChange = (event: React.ChangeEvent<{
        name?: string | undefined;
        value: unknown;
    }>, child: React.ReactNode) => {
        props.onPressureUnitChange(event);

        validatePressure(props.pressure as number, event.target.value as string);
    }

    return(
        <>
            <Grid item>
                <Grid
                    container
                    direction="column"
                    alignContent="flex-start"
                >
                    <div>
                        <TextField
                            style={props.style}
                            type="number"
                            value={props.density}
                            onChange={handleOnDensityChange}
                            name="density"
                            label="Density @ 15.6ºC"
                            error={densityError.length > 0}
                            helperText={densityError}
                            className={densityClassName}
                        />
                        <Select
                            style={{marginTop: "16px", marginLeft: "5px"}}
                            name="unit-density"
                            onChange={handleOnDensityUnitChange}
                            value={props.densityUnit}
                        >
                            <option value="GCM3">g/cm³</option>
                            <option value="SG">SG</option>
                            <option value="LBGAL">lb/gal</option>
                            <option value="PPTF">pptf</option>
                        </Select>
                    </div>

                    <div>
                        <TextField
                            style={props.style}
                            type="number"
                            name="temperature"
                            value={props.temperature}
                            onChange={handleOnTemperatureChange}
                            label="Temperature"
                            error={temperatureError.length > 0}
                            helperText={temperatureError}
                            className={temperatureClassName}
                        />
                        <Select
                            style={{marginTop: "16px", marginLeft: "5px"}}
                            name="unit-temperature"
                            onChange={handleOnTemperatureUnitChange}
                            value={props.temperatureUnit}
                        >
                            <option value="C">°C</option>
                            <option value="F">°F</option>
                        </Select>
                    </div>

                    <div>
                        <TextField
                            style={props.style}
                            type="number"
                            name="pressure"
                            value={props.pressure}
                            onChange={handleOnPressureChange}
                            label="Pressure"
                            error={pressureError.length > 0}
                            helperText={pressureError}
                            className={pressureClassName}
                        />
                        <Select
                            style={{marginTop: "16px", marginLeft: "5px"}}
                            name="unit-pressure"
                            onChange={handleOnPressureUnitChange}
                            value={props.pressureUnit}
                        >
                            <option value="PSI">psi</option>
                            <option value="BAR">bar</option>
                            <option value="MPA">MPa</option>
                            <option value="KPA">KPa</option>
                        </Select>
                    </div>
                </Grid>
            </Grid>
        </>
    );
}