import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  BrowserView,
  MobileView,
  isBrowser
} from "react-device-detect";
import Calculator from './components/Calculator';
import Footer from './components/Footer';

import useFeature from './hooks/useFeature';
import header from './assets/header.png';
import "./App.css";

function App() {
  if(isBrowser) {
    document.body.className = "browser-body";
  }

  let horizontalFlow = useFeature('horizontal_flow');

  // https://www.geeksforgeeks.org/how-to-check-a-webpage-is-loaded-inside-an-iframe-or-into-the-browser-window-using-javascript/
  let browserHeight = (window.location !== window.parent.location) ? 'calc(100% - 40px)' : 'auto';

  const params = new URLSearchParams(window.location.search);
  if(params.get('fullscreen') === 'true') browserHeight = 'auto';

  return (
    <>
      <MobileView style={{height: '100%'}}>
        <Grid container style={{height: 'calc(100% - 40px)'}}>
          <Grid item xs={12} md={12} lg={12} style={{marginTop: "25px", marginBottom: "25px"}}>
            <Grid
              container
              alignItems="center"
              direction="column"
            >
              <Grid item md={12} lg={12}>
                <img src={header} className="header" alt="" />
              </Grid>
              <Calculator />
            </Grid>
          </Grid>

          <Grid item>
            <Footer position="static" />
          </Grid>
        </Grid>
      </MobileView>

      <BrowserView style={{height: '100%'}}>
        <Grid container style={{height: browserHeight}}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid
              container
              alignItems="center"
              direction="column"
            >
              <Grid
                item
                md={(horizontalFlow.on) ? 11 : 11}
                lg={(horizontalFlow.on) ? 11 : 11}
                style={{marginTop: "25px", marginBottom: "25px"}}
              >
                  <Grid item md={12} lg={12}>
                    <img src={header} className="header" alt="" />
                  </Grid>
                  <div style={{marginLeft: "15px"}}>
                    <Calculator />
                  </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Footer position="relative" />
      </BrowserView>
    </>
  );
}

export default App;
