import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText'
import Button from '@material-ui/core/Button';
import useFeature from '../../hooks/useFeature'

interface IDisclaimerProps {
    open:boolean,
    onClose:() => void
}

export default function Disclaimer({open, onClose}:IDisclaimerProps) {
    const termsOfUseDialogText = useFeature('terms_of_use_dialog_text');
    const termsOfUseDialogTitle = useFeature('terms_of_use_dialog_title');

    return (
        <>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="disclaimer-dialog-title"
                aria-describedby="disclaimer-dialog-description"
                maxWidth="md"
            >
                <DialogTitle id="disclaimer-dialog-title">
                    {
                        (termsOfUseDialogTitle.on && termsOfUseDialogTitle.value)
                        ?
                        termsOfUseDialogTitle.value
                        :
                        `Terms of Use`
                    }
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {
                            (termsOfUseDialogText.on && termsOfUseDialogText.value)
                            ?
                            termsOfUseDialogText.value.split('\n').map(text => <p>{text}</p>)
                            :
                            (
                            <>
                            <p>The software conveying this information is provided to facilitate use of this information. No guarantee or warranty as to the software is given or implied. SINOMINE SPECIALTY FLUIDS LTD. DISCLAIMS ALL WARRANTIES EXPRESS OR IMPLIED, INCLUDING MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE AS TO SUCH SOFTWARE.</p>
                            <p>UNDER NO CIRCUMSTANCES SHALL SINOMINE SPECIALTY FLUIDS BE LIABLE FOR ANY INCREMENTAL. SPECIAL OR CONSEQUENTIAL DAMAGES (including loss of use. data, business of profits) ARISING FROM OR RELATING TO THE USE OR INABILITY TO USE THE SOFTWARE, WHETHER SUCH LIABILITY ARISES FROM A CLAIM BASED ON CONTRACT, WARRANTY, TORT, STRICT LIABILITY, PRODUCT LIABILITY OR OTHERWISE, EVEN IF SINOMINE SPECIALTY FLUIDS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. In no event is Sinomine Specialty Fluids responsible for, and Sinomine Specialty Fluids does not accept and hereby disclaims liability for, any damages whatsoever in connection with the use of or reliance on the software.</p>
                            </>
                            )
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose}>Ok</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}