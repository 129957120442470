import { useState, useEffect } from 'react';
import flagsmith from 'flagsmith';

interface IFeature {
    on:boolean,
    value:string
}

export default function useFeature(featureName:string):IFeature {
    let [feature, setFeature] = useState<IFeature>({on: false, value: ''});

    useEffect(() => {
        flagsmith.init({
            environmentID: 'ncRHnpCP8FeDTcFXd92Jph',
            onChange: () => {
                const value = flagsmith.getValue(featureName);
                const isOn = flagsmith.hasFeature(featureName);

                setFeature({on:isOn, value:String(value)});
            }
        });
    }, [featureName]);

    return feature;
}