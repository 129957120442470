import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Disclaimer from '../Disclaimer';
import useFeature from '../../hooks/useFeature';

type props = {
    position:"fixed" | "absolute" | "relative" | "static" | "sticky" | undefined
}

export default function Footer({position}:props) {
    const [open, setOpen] = useState<boolean>(false);
    const footerDisclaimerTextFeature = useFeature('footer_disclaimer_text');
    const footerCopyrightTextFeauture = useFeature('footer_copyright_text');

    return (
        <>
            <AppBar
                position={position}
                style={{
                    bottom: 0,
                    backgroundColor: "#000000"
                }}
            >
                <Toolbar style={{
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: '40px',
                    height: '40px'
                    }}
                >
                    <div>
                        <Typography
                            display="block"
                            align="center"
                        >
                            {
                                /**
                                 * The text between 2 brackets in the disclaimer feature will be a link to open the
                                 * disclaimer dialog. That's why we split the value when this string between the brackets
                                 * is matched. To insert the link between the results we check if the index of the map function
                                 * is odd.
                                 */
                                (footerDisclaimerTextFeature.on && footerDisclaimerTextFeature.value)
                                ?
                                footerDisclaimerTextFeature.value.split(/\{\{.+\}\}/g).map((text, idx) =>
                                    {
                                        const match = footerDisclaimerTextFeature.value.match(/\{\{.+\}\}/g);
                                        if(idx % 2 === 0) {
                                            return (
                                                <>
                                                    {text}
                                                    {(match) &&
                                                    (
                                                    <a
                                                        onClick={() => setOpen(true)}
                                                        style={{
                                                            color: "white",
                                                            cursor: "pointer",
                                                            textDecoration: "underline"
                                                        }}
                                                    >
                                                        {match[0].replace('{{', '').replace('}}', '')}
                                                    </a>
                                                    )
                                                    }
                                                </>
                                        )}
                                        else {
                                            return (<>{text}</>)
                                        }
                                    })
                                :
                                (<>Disclaimer: By using this software you agree with our &#8201;
                                <a
                                    onClick={() => setOpen(true)}
                                    style={{
                                        color: "white",
                                        cursor: "pointer",
                                        textDecoration: "underline"
                                    }}
                                >
                                    terms of use
                                </a>.</>)
                            }
                        </Typography>
                        {
                            (footerCopyrightTextFeauture.on && footerCopyrightTextFeauture.value) &&
                            <Typography
                                display="block"
                                align="center"
                            >
                                Copyright 2021. All rights reserved worldwide.
                            </Typography>
                        }
                    </div>
                </Toolbar>
            </AppBar>
            <Disclaimer open={open} onClose={() => setOpen(false)} />
        </>
    );
}