import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';

interface OutputFieldsProps {
    density: string,
    densityUnit: string,
    onDensityUnitChange: any,

    compressibility: string,
    compressibilityUnit: string,
    oncompressibilityUnitChange: any,

    thermalExpansion: string,
    thermalExpansionUnit: string,
    onthermalExpansionUnitChange: any,

    style?:object
}

export default function OutputFields(props:OutputFieldsProps) {
    return (
        <Grid item>
            <Grid
                container
                alignContent="flex-start"
                direction="column"
                >
                    <div>
                        <TextField
                            style={props.style}
                            value={props.density}
                            name="density-out"
                            label="Density"
                            disabled
                        />
                        <Select
                            style={{marginTop: "16px", marginLeft: "5px"}}
                            value={props.densityUnit}
                            onChange={props.onDensityUnitChange}
                        >
                            <option value="GCM3">g/cm³</option>
                            <option value="SG">SG</option>
                            <option value="LBGAL">lb/gal</option>
                            <option value="PPTF">pptf</option>
                        </Select>
                    </div>

                    <div>
                        <TextField
                            style={props.style}
                            value={props.compressibility}
                            name="compressibility-out"
                            disabled
                            label="Compressibility"
                        />
                        <Select
                            style={{marginTop: "16px", marginLeft: "5px"}}
                            value={props.compressibilityUnit}
                            onChange={props.oncompressibilityUnitChange}
                        >
                            <option value="1/PSI">1/psi</option>
                            <option value="1/BAR">1/bar</option>
                            <option value="1/PA">1/pa</option>
                        </Select>
                    </div>

                    <div>
                        <TextField
                            style={props.style}
                            value={props.thermalExpansion}
                            name="thermal-expansion-out"
                            disabled
                            label="Thermal expansion"
                        />
                        <Select
                            style={{marginTop: "16px", marginLeft: "5px"}}
                            value={props.thermalExpansionUnit}
                            onChange={props.onthermalExpansionUnitChange}
                        >
                            <option value="1/K">1/K</option>
                            <option value="1/C">1/C</option>
                            <option value="1/F">1/F</option>
                        </Select>
                    </div>
        </Grid>
      </Grid>
    );
}


